class MileageMoneyRoot {
  isOpened: boolean;
  constructor() { }

  toggle(): void {
    this.isOpened ? this.close(): this.open();
  }

  private open(): void {
    let folder   = document.getElementsByTagName('mm-root')[0].getElementsByClassName('animate-height')[0];
    let height = [...<any>folder.children].filter(el => el.localName === 'nested-list-item').reduce((sum, val) => sum = sum + +val?.children[0]?.offsetHeight, 0);
    folder.style.height = height + 'px';
    this.isOpened = true;
  }

  private close(): void {
    let folder   = document.getElementsByTagName('mm-root')[0].getElementsByClassName('animate-height')[0];
    folder.style.height = '0px';
    this.isOpened = false;
  }
}

window.app.component('mmRoot', {
  template: require('scripts/components/mileage-money/mm-root/mm-root.html'),
  controller: [MileageMoneyRoot]
});
